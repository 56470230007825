<template>
    <el-dialog
        width="500px"
        top="40px"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :before-close="() => !$wait.is('*') && $emit('close')"
        @closed="resetForm"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('map.add_poi') }}
                </p>
            </div>
        </span>

        <div class="mb-14 mt-8 px-10">
            <el-form ref="form" :model="formData" label-position="top" :rules="rules" @submit.native.prevent @keyup.native.enter="addService">
                <el-form-item :label="$t('map.title')" prop="title">
                    <el-input v-model="formData.title" />
                </el-form-item>
                <el-form-item :label="$t('map.description')" prop="description">
                    <el-input v-model="formData.description" type="textarea" rows="3" />
                </el-form-item>
                <el-form-item :label="$t('common.google_map_search')">
                    <AutoComplete ref="autocomplete" @placeFound="placeFound" />
                </el-form-item>
                <el-form-item :label="$t('clients.street')" prop="street">
                    <el-input v-model="formData.street" />
                </el-form-item>
                <div class="grid grid-cols-2 gap-4">
                    <el-form-item :label="$t('clients.city')" prop="city">
                        <el-input v-model="formData.city" />
                    </el-form-item>
                    <el-form-item :label="$t('map.post_code')" prop="post_code">
                        <el-input v-model="formData.post_code" />
                    </el-form-item>
                </div>
            </el-form>

            <GoogleMap
                :coords="{
                    map_lat: formData.map_lat,
                    map_lng: formData.map_lng,
                }"
                :markerDraggable="true"
                @markerDragged="markerMapDragged"
            />
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer">
                <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" :loading="$wait.is('adding')" @click="addEntry">{{ $t('common.add') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>
<script>
import Api from './map_poi.api';

export default {
    components: {
        GoogleMap:    () => import(/* webpackChunkName: "GoogleMapForAddress" */ '@/components/GoogleMapForAddress/GoogleMapForAddress.vue'),
        AutoComplete: () => import(/* webpackChunkName: "GoogleAddressAutoComplete" */ '@/components/GoogleMapSearch/AutoComplete.vue'),
    },

    props: {
        clientId: {
            type:    Number,
            default: null,
        },
        visible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            formData: {
                title:       '',
                description: '',
                street:      '',
                city:        '',
                post_code:   '',
                icon:        'poi',
                map_lat:     59.32519065531827,
                map_lng:     18.06959644453127,
            },
            rules: {
                title:     [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                street:    [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                city:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                post_code: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        async addEntry() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            try {
                this.$wait.start('adding');
                await Api.create(this.clientId, this.formData);
                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
            } finally {
                this.$wait.end('adding');
                this.closeModal();
            }
        },

        markerMapDragged({ mapLat, mapLng }) {
            this.formData.map_lat = mapLat;
            this.formData.map_lng = mapLng;
        },

        placeFound(data) {
            this.formData.street = data.street;
            this.formData.city = data.city;
            this.formData.post_code = data.postcode;
            this.formData.map_lat = data.map_lat;
            this.formData.map_lng = data.map_lng;
        },

        closeModal() {
            this.$emit('close');
        },

        resetForm() {
            this.$refs.autocomplete.resetInput();
            this.$refs.form.resetFields();
            this.formData = {
                icon:    'poi',
                map_lat: 59.32519065531827,
                map_lng: 18.06959644453127,
            };
        },
    },
};
</script>
<style>
    .pac-container {
        display: inherit;
        z-index: 99999 !important;
    }
</style>
